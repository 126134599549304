export const FETCHING_STOCK_LIST = 'FETCHING_STOCK_LIST';
export type FETCHING_STOCK_LIST = typeof FETCHING_STOCK_LIST;

export const FETCHED_STOCK_LIST = 'FETCHED_STOCK_LIST';
export type FETCHED_STOCK_LIST = typeof FETCHED_STOCK_LIST;

export const FETCHING_STOCK_IMG_LIST = 'FETCHING_STOCK_IMG_LIST';
export type FETCHING_STOCK_IMG_LIST = typeof FETCHING_STOCK_IMG_LIST;

export const FETCHED_STOCK_IMG_LIST = 'FETCHED_STOCK_IMG_LIST';
export type FETCHED_STOCK_IMG_LIST = typeof FETCHED_STOCK_IMG_LIST;

export const ADDING_STOCK_IMG = 'ADDING_STOCK_IMG';
export type ADDING_STOCK_IMG = typeof ADDING_STOCK_IMG;

export const ADDED_STOCK_IMG = 'ADDED_STOCK_IMG';
export type ADDED_STOCK_IMG = typeof ADDED_STOCK_IMG;

export const FETCHING_ITEM_DETAILS = 'FETCHING_ITEM_DETAILS';
export type FETCHING_ITEM_DETAILS = typeof FETCHING_ITEM_DETAILS;

export const FETCHED_ITEM_DETAILS = 'FETCHED_ITEM_DETAILS';
export type FETCHED_ITEM_DETAILS = typeof FETCHED_ITEM_DETAILS;

export const UPDATING_STOCK_ITEM = 'UPDATING_STOCK_ITEM';
export type UPDATING_STOCK_ITEM = typeof UPDATING_STOCK_ITEM;

export const UPDATED_STOCK_ITEM = 'UPDATED_STOCK_ITEM';
export type UPDATED_STOCK_ITEM = typeof UPDATED_STOCK_ITEM;
